import { getData } from "./getData";

export const getTournament = async (token = null) => {
    let tournaments = (await getData('/api/tournament', token)).data;
    return tournaments;
}


export const getTournamentById = async (token = null, id) => {
    let tournaments = (await getData(`/api/tournament/${id}/getById`, token, false)).data;
    return tournaments;
}