import ALD from '../../assets/Images/ADS-MapleLeaf_LOGO.png';
import nsa from '../../assets/Images/nsa_logo.png';

export const Sponsers = ({ isMobile = false }) => {
    const center = { display: 'flex', justifyContent: 'center', alignItems: 'center' };
    return <div style={isMobile ? { ...center, alignItems: 'start', flexDirection: 'column' } : {}}>
        <h6>Sponsors</h6>
        <div>
            <a style={center} href="https://nsacanada.ca/" rel="noreferrer" target="_blank">
                <img src={nsa} style={{ height: '25px', width: '35px', marginRight: '5px' }} /> NSA Canada
            </a>
        </div>
        <br />
        <div>
            <a style={center} href="https://www.instagram.com/aldoesshirts/" rel="noreferrer" target="_blank">
                <img src={ALD} style={{ height: '25px', width: '25px', marginRight: '5px' }} /> Al Does Shirts
            </a>
        </div>
    </div>
}