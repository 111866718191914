import React, { useEffect, useState } from 'react';
import { Paper, Typography, CircularProgress, Box, Button } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
 
const Success = ({ title, url = null }) => {
    const [progress, setProgress] = useState(100);
    const reload = () => {
        if (url) {
            window.location.replace(url);
        } else {
            window.location.reload();
        }
    }
    useEffect(() => {
        const timer = setInterval(() => {
            setProgress((prevProgress) => (prevProgress <= 0 ? 100 : prevProgress - 20));
        }, 1000);

        return () => {
            clearInterval(timer);
        };
    }, []);

    useEffect(() => {
        if (progress <= 0) {
            reload();
        }
    }, [progress]);

    return (
        <Paper elevation={3} sx={{ p: 3, display: 'flex', flexDirection: 'column', alignItems: 'center', width: "100%" }}>
            <CheckCircleOutlineIcon color="success" sx={{ fontSize: 60 }} />
            <Typography variant="h5" sx={{ mt: 1 }}>{title}</Typography>
            <Box sx={{ position: 'relative', display: 'inline-flex', mt: 3 }}>
                <CircularProgress variant="determinate" value={progress} size={80} thickness={4} />
                <Box
                    sx={{
                        top: 0,
                        left: 0,
                        bottom: 10,
                        right: 0,
                        position: 'absolute',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Typography variant="caption" component="div" color="text.secondary">
                        {Math.round(progress / 20)}
                    </Typography>
                </Box>
            </Box>
            <Button onClick={reload} sx={{ width: { xs: '98%', md: '48%' }, m: '1%', borderRadius: '50rem' }} variant='contained' color='success'>Reload Now</Button>
        </Paper>
    );
};

export default Success;
