// const axios = require('axios').default;

export const userReducers = {
    populateArray(state, { payload }) {
        let users = payload || [];
        if (!Array.isArray(users)) return;
        //only add registrations we don't already have in the array
        users = users.filter(user => !(state.users.some(cachedUser => cachedUser._id === user._id)))
        state.users = [
            ...state.users,
            ...users
        ]
    },
    clearArray(state) {
        state.users = [];
    },
    siteUsersFetched(state) {
        state.fetchSiteUsers = false;
    }
}