import React, { useState, useRef } from 'react';
import { Checkbox, FormControlLabel, Dialog, DialogContent, DialogTitle, Button } from '@mui/material';

function AgreementCheckbox({ title, children, onSuccess, acceptTitle = "Agree" }) {
    const [open, setOpen] = useState(false);
    const [agreed, setAgreed] = useState(false);
    const contentRef = useRef(null);

    const handleOpen = () => {
        if (!agreed) {
            setOpen(true);
        }
    };

    const handleClose = (event) => {
        if (!event.isTrusted) return;
        setOpen(false);
    };

    const handleAgree = (event) => {
        if (!event.isTrusted) return;
        setAgreed(true);
        setOpen(false);
        onSuccess(true); // Update the external state
    };

    return (
        <>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={agreed}
                        onChange={handleOpen}
                        style={{ color: agreed ? 'green' : undefined }}
                    />
                }
                label={`I agree to the ${title}`}
            />
            <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth scroll="paper">
                <DialogTitle>{title}</DialogTitle>
                <DialogContent dividers={true} ref={contentRef}>
                    {children}
                </DialogContent>
                <div style={{ display: 'flex', justifyContent: 'flex-end', margin: '20px' }}>
                    <Button onClick={handleClose} color="primary">Disagree</Button>
                    <Button onClick={handleAgree} color="primary">{acceptTitle}</Button>
                </div>
            </Dialog>
        </>
    );
}

export default AgreementCheckbox;
