import { createSlice } from '@reduxjs/toolkit';
import { paymentReducers } from './paymentReducers';

const paymentInit = {
    payments: [],
    making: {
        selected: [],
        makePayment: false,
        show: false
    }
}

export const paymentSlice = createSlice({
    name: 'payments',
    initialState: paymentInit,
    reducers: paymentReducers
});
export const paymentActions = paymentSlice.actions; 