import AccessTimeIcon from '@mui/icons-material/AccessTime';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Box } from "@mui/system";
import SportsBaseballIcon from '@mui/icons-material/SportsBaseball';
import { useSelector, useDispatch } from 'react-redux';
import { registrationActions } from '../../../../Store/Slices/Registrations/registrationSlice';
import { useState } from 'react';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import EditIcon from '@mui/icons-material/Edit';

const RegisterButton = ({ tournament, count, isDashoard }) => {
    const { divisions, externalLink, _id, EntryDeadline, dates } = tournament
    const dispatch = useDispatch();
    const userRegistrations = useSelector(state => state.user.registrations);
    const userRosters = useSelector(state => state.user.rosters);
    const user = useSelector(state => state.user.account);

    const registrations = isDashoard ? [] : userRegistrations; //no registration on dashboard
    const allRosters = isDashoard ? [] : userRosters; //no rosters on dashboard

    const [isLoading, setIsLoading] = useState(false);

    const register = (tournamentId, isWaitlist, division, isRegistering = true) => {
        dispatch(registrationActions.startRegistration({ isRegistering, isWaitlist, tournamentId, division, setIsLoading, isDashoard }))
    };

    const onRoster = (roster) => {
        const registration = registrations.find(reg => reg.tournamentId === _id)
        dispatch(registrationActions.startRoster({
            registration,
            tournament,
            roster
        }))
    };

    const onRegister = (id, division) => {
        setIsLoading(true);
        register(id, false, division)
    }

    const onWaitlist = (id, division) => {
        setIsLoading(true);
        register(id, true, division)
    }

    const isRunning = (dates) => {
        if (!dates || dates.length === 0) return false;
        const currentDateTimestamp = new Date().getTime(); // Current date's timestamp in milliseconds
        const startDateTimestamp = dates[0].start; // Assuming 'start' is in timestamp format
        const endDateTimestamp = dates.at(-1).end + (24 * 60 * 60 * 1000); // Adding one day to the last 'end' date

        return currentDateTimestamp >= startDateTimestamp && currentDateTimestamp <= endDateTimestamp;
    };


    //check if the button is loading
    if (isLoading) {
        return <Button disabled fullWidth variant='outlined' sx={{ borderRadius: '50rem' }}>
            <CircularProgress size={30} />
        </Button>
    }
    //check if this is an NSA tourny. If its is return a linked button
    if (tournament.tournamentType.trim().includes("NSA")) {
        return <Button fullWidth color='error' variant='outlined' sx={{ borderRadius: '50rem' }} href={externalLink} target='_blank'>Register (NSA)</Button>
    }

    if (isRunning(dates)) {
        return <Button
            disabled
            key={`playing`}
            sx={{ borderRadius: '50rem', width: '98%', m: '1%' }}
            variant="outlined"
            color="playing">
            {`Running now`}
        </Button>
    }


    //return all the division that this tournament has
    return <Box sx={{ width: '100%', display: { xs: "flex", md: "inherit" }, flexDirection: { xs: "column", md: "inherit" } }}>
        {divisions.map((div, index) => {
            const roster = allRosters.find(r => r.tournamentId === tournament._id && r.division === div);
            const isRegistered = registrations.some(reg => (reg.tournamentId === _id) && !reg.isWaitlisted && (reg.team.division === div));
            const isWaitlisted = registrations.some(reg => (reg.tournamentId === _id) && reg.isWaitlisted && (reg.team.division === div));
            const isTOC = tournament.tournamentType.trim().includes("TOC");
            const rosterLocked = new Date() > new Date(dates[0].start);
            const deadlinePassed = new Date() > new Date(EntryDeadline);
            let draft = user.username !== '' && user.playerStats.draftRosters ? user.playerStats.draftRosters[`${tournament._id}`] : null;
            
            //When somone is registered for TOC, they can only see the division they are registered for
            if(isTOC && (!isRegistered && !isWaitlisted)) return;

            //if the user has already registered - roster is locked
            if (isRegistered && roster && rosterLocked) {
                return <Button
                    key={`${div}-${index}`}
                    startIcon={<CheckCircleIcon />}
                    sx={{ borderRadius: '50rem', width: '98%', m: '1%' }}
                    variant="contained"
                    color="success">
                    {`Registered (${div})`}
                </Button>
            }

            //Roster is submitted - now user can edit it
            if (isRegistered && roster && !rosterLocked) {
                return <Button
                    key={`${div}-${index}`}
                    startIcon={<EditIcon />}
                    onClick={() => onRoster(roster)}
                    sx={{ borderRadius: '50rem', width: '98%', m: '1%' }}
                    variant="contained"
                    color="success">
                    {`Edit Roster (${div})`}
                </Button>
            }

            //submit a brand new roster
            if (isRegistered && !roster && !rosterLocked) {
                return <Button
                    key={`${div}-${index}`}
                    startIcon={<MenuBookIcon />}
                    onClick={() => onRoster(roster)}
                    sx={{ borderRadius: '50rem', width: '98%', m: '1%' }}
                    variant="outlined"
                    color="success">
                    {draft && !roster ? `Finish Roster (${div})` : `Create Roster (${div})`}
                </Button>
            }

            //if the deadline for registration has passed show that
            if (deadlinePassed) {
                return <Button startIcon={<AccessTimeIcon />} fullWidth disabled sx={{ borderRadius: '50rem', width: '100%', m: '1%' }} variant="contained">{`Deadline Passed (${div})`}</Button>
            }

            //if the tournament is full. Add the team to the waitlist
            if (count <= 0) {
                return isWaitlisted && !isDashoard ?
                    <Button disabled startIcon={<i class="fa-solid fa-people-group"></i>} fullWidth variant='contained' sx={{ borderRadius: '50rem', width: '98%', m: '1%' }}>On Waitlist ({div})</Button> :
                    <Button onClick={() => onWaitlist(_id, div)} startIcon={<i class="fa-solid fa-people-group"></i>} fullWidth variant='outlined' sx={{ borderRadius: '50rem', width: '98%', m: '1%' }} value={div}>Join the waitlist ({div})</Button>
            }

            //new registration button
            return <Button
                variant='contained'
                key={`${div}-${index}`}
                onClick={() => onRegister(_id, div)}
                id={div}
                startIcon={<SportsBaseballIcon />}
                sx={{ borderRadius: '50rem', width: '98%', m: '1%' }}
                value={div}>
                {div}
            </Button>
        })}
    </Box>
}
export default RegisterButton;