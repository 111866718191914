import Theme from './shared/styling/Theme';
import './shared/styling/master.css'
import Routing from './Routes';
import { useSelector } from "react-redux";
import LoadingPage from "./LoadingPage";
import RegistrationModel from './shared/components/tournaments/Registrations/RegistrationModel';
import { isMobile } from './shared/lib/isMobile';
import { UpdateDetails } from './shared/components/UpdateDetails';
import RosterModel from './shared/components/tournaments/Rosters/RosterModel';
import { EditRegistrations } from './pages/Dashboard/Registrations/components/EditRegistration';

const App = () => {
    const loading = useSelector(state => state.loading.loading);
    const mobile = isMobile();
    return loading.finsished ?
        <div style={{ paddingBottom: mobile ? "75px" : "0px"}}>
            <Theme>
                <Routing />
                <RegistrationModel />
                <UpdateDetails />
                <RosterModel />
                <EditRegistrations />
            </Theme>
        </div> :
        <LoadingPage />
}

export default App;
