import * as React from 'react';
import Modal from '@mui/material/Modal';
import { useSelector, useDispatch } from 'react-redux';
import { registrationActions } from '../../../../Store/Slices/Registrations/registrationSlice';
import RosterArea from './RosterArea';

export default function RosterModel() {
    const dispatch = useDispatch();
    const { tournament, registration, roster, view, isDashboard } = useSelector(state => state.registrations.rosterCreation);
    const handleClose = () => dispatch(registrationActions.endRoster());
    return (
        <div>
            <Modal
                open={tournament ? true : false}
                onClose={handleClose}
                aria-labelledby="registration-modal"
                aria-describedby="model for tournament registration"
            >
                <RosterArea tournament={tournament} registration={registration} roster={roster} viewing={view} isDashboard={isDashboard} />
            </Modal>
        </div>
    );
} 