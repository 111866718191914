import * as React from 'react';
import Modal from '@mui/material/Modal';
import { useSelector, useDispatch } from 'react-redux';
import { registrationActions } from '../../../../Store/Slices/Registrations/registrationSlice';
import RegistrationArea from './RegistrationArea';

export default function RegistrationModel() {
    const dispatch = useDispatch();
    const registering = useSelector(state => state.registrations.registering);
    const { isRegistering, tournamentId, division, isWaitlist, setIsLoading, isDashoard } = registering;
    const allEvents = useSelector(state => state.tournaments.tournaments);
    const tournament = allEvents.find(event => String(event._id) === tournamentId);

    const handleClose = () => {
        setIsLoading(false);
        dispatch(registrationActions.endRegistration());
    }

    return (
        <div>
            <Modal
                open={isRegistering}
                onClose={handleClose}
                aria-labelledby="registration-modal"
                aria-describedby="model for tournament registration"
            >
                <RegistrationArea tournament={tournament} division={division} isWaitlist={isWaitlist} close={handleClose} isDashoard={isDashoard} />
            </Modal>
        </div>
    );
} 