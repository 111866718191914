import { configureStore } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { userSlice, userActions } from './Slices/User/userSlice'
import { messageSlice } from "./Slices/MessageHandling/messageSlice";
import { tournamentActions, tournamentSlice } from "./Slices/Tournaments/tournamentSlice";
import { productSlice, productActions } from './Slices/Products/productSlice'
import { documentSlice, documentActions } from "./Slices/Documents/documentsSlice";
import { FaqActions, FaqSlice } from "./Slices/Faq/FaqSlice";
import { cartActions, cartSlice } from './Slices/Cart/cartSlice';
import { registrationActions, registrationSlice } from "./Slices/Registrations/registrationSlice";
import { orderSlice, orderActions } from './Slices/Orders/orderSlice';
// import { allUserActions, allUserSlice } from './Slices/Users/userSlice';
import { paymentActions, paymentSlice } from './Slices/Payments/paymentSlice';
import { metricsActions, metricsSlice } from "./Slices/Metrics/metricsSlice";
import { loadingSlice, loadingActions } from "./Slices/Loading/loadingSlice";
import { allUserActions, allUserSlice } from './Slices/Users/userSlice';
import { notificationsActions, notificationsSlice } from "./Slices/Notifications/notificationSlice";
import { rosterActions, rosterSlice } from "./Slices/Rosters/rostersSlice";
import axios from "axios";
import { getData } from "../shared/Api/getData";

/**
 * Redux Store
 */
const reduxStore = configureStore({
    reducer: {
        messages: messageSlice.reducer,
        user: userSlice.reducer,
        users: allUserSlice.reducer,
        tournaments: tournamentSlice.reducer,
        products: productSlice.reducer,
        documents: documentSlice.reducer,
        faq: FaqSlice.reducer,
        cart: cartSlice.reducer,
        registrations: registrationSlice.reducer,
        orders: orderSlice.reducer,
        payments: paymentSlice.reducer,
        metrics: metricsSlice.reducer,
        loading: loadingSlice.reducer,
        notifications: notificationsSlice.reducer,
        rosters: rosterSlice.reducer
    }
});
/**
 * React Startup Component for our redux store
 * this will populate needed data
 */
const ReduxStoreStartup = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(loadingActions.setStage(25));
        (async () => {
            //fetch token and divisions
            const token = await getData('/api/user/auth')
            if (!token.data.token) {
                return dispatch(loadingActions.finishLoading());
            }
            dispatch(userActions.setToken(token.data.token));
            dispatch(loadingActions.setStage(50));
            //get basic data
            let account = await getData('/api/user', token.data.token, false)
            if (!account.data.account) {
                return dispatch(loadingActions.finishLoading());
            }

            //if there is account data, dispatch it
            dispatch(loadingActions.setStage(75));
            dispatch(userActions.setAccount(account.data.account));
            dispatch(userActions.setRegistrations(account.data.registrations));
            dispatch(userActions.setOrders(account.data.orders));
            dispatch(userActions.setPayments(account.data.payments));
            dispatch(userActions.setRosters(account.data.rosters));
            dispatch(userActions.login());

            dispatch(loadingActions.finishLoading());
        })();
    });
}

export { ReduxStoreStartup, reduxStore }