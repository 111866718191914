
import Modal from '@mui/material/Modal';
import { useSelector, useDispatch } from 'react-redux';
import { Alert, Backdrop, Box, Button, FormControl, Grid, IconButton, InputLabel, MenuItem, Paper, Select, TextField, Tooltip, Typography } from '@mui/material';
import Login from '../../pages/Login/Login';
import logo from '../../assets/Images/logo.webp';
import { useState, useEffect } from 'react';
import InfoIcon from '@mui/icons-material/Info';
import AgreementCheckbox from '../../pages/Login/components/AgreementCheckbox';
import waiver from '../../data/waiver.json';
import AddressForm from '../../pages/Login/components/AddressForm';
import axios from 'axios';

export const UpdateDetails = () => {
    const dispatch = useDispatch();
    const [error, setError] = useState('');
    const userDetails = useSelector(state => state.user.account);
    const token = useSelector(state => state.user.token);
    const [user, setUser] = useState(userDetails);
    const [waiverAgreed, setwaiverAgreed] = useState(false);
    if(!user) return;
    const Axios = axios.create({
        headers: { 'Authorization': `Bearer ${token}` }
    });

   

    const setUserHandler = (event) => {
        const { id, value } = event.target;
        setUser((prevs) => ({
            ...prevs,
            [id]: value
        }));
    }
    const updateRank = (event) => {
        const rank = event.target.value;
        setUser((prevs) => ({
            ...prevs,
            playerStats: {
                rank
            }
        }));
    }
    const updateAddress = (address) => {
        setUser((prevs) => ({
            ...prevs,
            address: address
        }));
    }

    const sendRegisterRequest = (event) => {
        if (!event.isTrusted) return;
        if (!user.playerStats.rank || user.playerStats.rank.length < 1) {
            setError("Please fill in a your rank. IF you're not a play please select 'Not Sure'");
            return;
        }
        if (!user.address.city || user.address.city.length < 4) {
            setError('Please fill in a your city');
            return;
        }
        if (!user.address.province || user.address.province.length < 2) {
            setError('Please fill in a your province');
            return;
        }

        Axios({
            method: 'put',
            url: '/api/user',
            data: { user }
        }).then(() => {
            setError('');
            window.location.replace('/')
        }).catch((error) => {
            const response = error.response.data;
            if (response === 'Unauthorized') {
                setError('Incorrect username or password.');
                return;
            }
            if (response.includes('No account')) {
                setError(response);
            }
            setError(response);
        });
    }

    return user && user.playerStats && <div>
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={!userDetails.playerStats.rank}
        >
            <Paper sx={{ padding: '10px 1%', margin: '1%', overflow: 'auto', maxHeight: '99vh' }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <img alt='Slo pitch for awanress logo' src={logo} />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography align="center" variant="h5"> Update Information </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box display="flex" alignItems="center">
                            <FormControl fullWidth variant="outlined">
                                <InputLabel id="demo-simple-select-filled-label">Player Rank</InputLabel>
                                <Select
                                    labelId="demo-simple-select-filled-label"
                                    id="demo-simple-select-filled"
                                    value={user.playerStats.rank}
                                    label="Player Rank"
                                    onChange={updateRank}
                                >
                                    <MenuItem value={"E"}>E</MenuItem>
                                    <MenuItem value={"D"}>D</MenuItem>
                                    <MenuItem value={"C"}>C</MenuItem>
                                    <MenuItem value={"B"}>B</MenuItem>
                                    <MenuItem value={"A"}>A</MenuItem>
                                </Select>
                            </FormControl>
                            <Tooltip title="Please select your highest tournament level you have played or are ranked in any association. Failure to select the proper rank could result in disciplinary action up to, and including, suspension. Once you are registered on the SPFA Canada Player Classification system if you move up in level of play it is the players responsibility to notify SPFA Canada. If you're not currently registered with any organization enter yuor ranking as E">
                                <IconButton>
                                    <InfoIcon color={"primary"} />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sx={{ padding: '2% 0' }}>
                        <AddressForm updateAddress={updateAddress} />
                    </Grid>
                    <Grid item xs={12}>
                        <AgreementCheckbox title={"Player waiver"} open={true} onSuccess={() => setwaiverAgreed(true)}>
                            {waiver["en"]}
                        </AgreementCheckbox>
                    </Grid>
                    <Grid item xs={12}>
                        {error && <Alert sx={{ width: '100%', m: '1rem 0' }} severity="error">{error}</Alert>}
                        {waiverAgreed ?
                            <Button onClick={sendRegisterRequest} color={'success'} fullWidth sx={{ borderRadius: '50rem' }} variant='contained'>{`Update Account`}</Button> :
                            <Button disabled onClick={sendRegisterRequest} color={'success'} fullWidth sx={{ borderRadius: '50rem' }} variant='contained'>{`Update Account`}</Button>
                        }
                    </Grid>
                </Grid>
            </Paper>
        </Backdrop>
    </div>
}