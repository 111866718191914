import axios from "axios";

export const getData = async (url, token, optionalToken = true, additionalHeaders = {}) => {
    try {
        if (!optionalToken && !token) return { data: [] }
        const headers = token ? {
            'Authorization': `Bearer ${token}`,
            ...additionalHeaders
        } : additionalHeaders;

        const res = await axios({
            method: 'get',
            url: url,
            headers: headers
        })
        return res
    } catch (err) {
        console.error(`Failed to get data for ${url}`, err)
        return { data: [] }
    }
}