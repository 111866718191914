import axios from "axios";

export class Logger {
    constructor(caller) {
        this.caller = caller;
    }

    async #log(level, message) {
        return await axios({
            method: "post",
            url: '/api/log',
            data: {
                type: level,
                message: message,
                caller: this.caller
            }
        })
    }

    async trace(message) {
        this.#log('silly', message);
        if(process.env.NODE_ENV !== 'development') return;
        console.log(`[trace][${this.caller}] ${message}`)
    }

    async info(message) {
        this.#log('info', message);
        if (process.env.NODE_ENV !== 'development') return;
        console.debug(`[info][${this.caller}] ${message}`)
    }

    async warn(message) {
        this.#log('warn', message);
        if (process.env.NODE_ENV !== 'development') return;
        console.warn(`[warn][${this.caller}] ${message}`)
    }

    async error(message) {
        this.#log('error', message);
        if (process.env.NODE_ENV !== 'development') return;
        console.error(`[error][${this.caller}] ${message}`)
    }

    async fatal(message, page) {
        this.#log('fatal', message);
        if (process.env.NODE_ENV !== 'development') return;
        console.error(`[fatal][${this.caller}] ${message}`)
    }
}