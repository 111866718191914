import { useDispatch, useSelector } from "react-redux";
import { registrationActions } from "../../../../Store/Slices/Registrations/registrationSlice";
import { Backdrop, Box, Button, Dialog, DialogContent, DialogTitle, Divider, Grid, Paper, Stack, Typography } from "@mui/material";
import SearchBar from "../../SearchBar";
import { useEffect, useState } from "react";
import { Add, Remove, CheckCircleOutline } from '@mui/icons-material/';
import { maskPhoneNumber } from "../../../lib/maskPhoneNumber";
import { PlayerItem } from "./PlayerItem";
import axios from "axios";
import Success from "../../Success";
import { removeKeyFromObjectCopy } from "../../../lib/removeKeyFromObject";
import { Logger } from "../../../Logger";
import rosterVerificationText from '../../../../data/rosterVerification.json';

const RosterMap = {
    "D": {
        max: 2,
        E: 0,
        D: 0,
        C: 1,
        B: 2,
        A: 3,
        message: 'D division can have D/E players plus 2 C or 1 B player.'
    },
    "int": {
        max: 5,
        E: 0,
        D: 0,
        C: 1,
        B: 2,
        A: 3,
        message: 'Int division can have D/ E players plus 4 C or 2 C / 1 B or 1 C / 1 A players.'
    },
    "rec": {
        max: 3,
        E: 0,
        D: 1,
        C: 2,
        B: 3,
        A: 4,
        message: 'Rec divisions can have E players plus 1 C or 2 D players.'
    },
    "open": {
        max: 1,
        E: 0,
        D: 0,
        C: 0,
        B: 0,
        A: 0,
        message: 'Open division have no limit on the rank of players that can play.'
    }
}
const logger = new Logger('RosterArea');

const RosterArea = ({ tournament, registration, roster, viewing, isDashboard = false }) => {
    const user = useSelector(state => state.user.account);
    const token = useSelector(state => state.user.token);
    const [success, setSuccess] = useState({
        show: false,
        title: ''
    });
    const [usedLastRoster, setUsedLastRoster] = useState(false);
    let lastRosterForDivision = user.username !== '' && user.playerStats.pastRosters ? user.playerStats.pastRosters[registration.team.division] : null;
    const [rules, setRules] = useState(RosterMap['rec']);
    const [count, setCount] = useState(0)
    const [players, setPlayers] = useState([]);
    const [isSearching, setIsSearching] = useState(false);
    const [validRoster, setIsValidRoster] = useState(true);
    const [searched, setSearched] = useState({ results: [], wasSearched: false });
    const [rosterVerified, setRosterVerified] = useState({
        verified: false,
        show: false
    })
    const dispatch = useDispatch();
    const onClose = () => {
        logger.info(`Now closing roster overlay for ${tournament.location.diamond}, ${tournament.location.city}`);
        dispatch(registrationActions.endRoster());
    }

    const isValidRoster = (players = [], divRules) => {
        const total = players.reduce((acc, player) => (acc + divRules[player.rank]), 0);
        return [total < rules.max, total];
    }

    const onSetPlayers = (players = [], divRules) => {
        if (!players.length) return;
        const [validRoster, total] = isValidRoster(players, divRules);
        console.log('validRoster', validRoster);
        console.log('total', total);
        setPlayers(players);
        setCount(total);
        if (!validRoster) {
            logger.warn(`Invalid roster set for ${tournament.location.diamond}, ${tournament.location.city} in division "${registration.team.division}"`)
            setIsValidRoster(false);
        }
    }

    const getDivision = () => {
        if (registration.team.division.toLowerCase().includes('int')) {
            return 'int'
        } else if (registration.team.division.toLowerCase().includes('rec')) {
            return 'rec'
        } else if (registration.team.division.toLowerCase().includes('open')) {
            return 'open'
        }
        return 'rec';
    }

    useEffect(() => {
        logger.info(`Now showing roster overlay for ${tournament.location.diamond}, ${tournament.location.city}`);
        let draft = user.username !== '' && user.playerStats.draftRosters ? user.playerStats.draftRosters[`${tournament._id}`] : null;
        let defaultRoster = [];
        if (!roster && !draft && !isDashboard) {
            defaultRoster.push({
                city: user.address.city,
                firstName: user.name.givenName,
                lastName: user.name.familyName,
                phone: maskPhoneNumber(user.phone),
                rank: user.playerStats.rank,
                photo: user.profileImage
            });
        }
        if ((roster || draft)) {
            if (roster) {
                defaultRoster = roster.players
            } else if (draft) {
                defaultRoster = draft.players
            }
        }
        const division = getDivision();
        logger.info(`Now setting roster rules for ${division} division for ${tournament.location.diamond}, ${tournament.location.city}`);
        setRules(RosterMap[division]);
        onSetPlayers(defaultRoster, RosterMap[division]);
    }, [user]);

    const onSearchComplete = (searched) => {
        logger.info(`Now updating roster search with found players for ${tournament.location.diamond}, ${tournament.location.city}`);
        const filteredArray2 = searched.filter(item2 => {
            return !players.some(item1 => {
                return item1.city === item2.city &&
                    item1.firstName === item2.firstName &&
                    item1.lastName === item2.lastName &&
                    item1.phone === item2.phone &&
                    item1.rank === item2.rank &&
                    item1.photo === item2.photo;
            });
        });
        setSearched({ results: filteredArray2, wasSearched: true });
        logger.info(`Successfully updated roster search with found players for ${tournament.location.diamond}, ${tournament.location.city}`);
        setIsSearching(false);
    }

    const onSearchClear = () => {
        logger.info(`Now clear roster player search for ${tournament.location.diamond}, ${tournament.location.city}`);
        setSearched({ results: [], wasSearched: false })
        logger.info(`Successfully cleared roster player search for ${tournament.location.diamond}, ${tournament.location.city}`);
    }

    const onRemovePlayer = (player, index) => {
        logger.info(`Now removeing player from roster for ${tournament.location.diamond}, ${tournament.location.city}`);
        let filteredPlayers = players.filter(p => !(p.firstName === player.firstName && p.lastName === player.lastName && p.phone === player.phone))
        onSetPlayers(filteredPlayers, rules);
        logger.info(`Successfully removed player from roster for ${tournament.location.diamond}, ${tournament.location.city}`);
    }

    const onAddPlayer = (player) => {
        logger.info(`Now adding new player to roster for ${tournament.location.diamond}, ${tournament.location.city}`);
        onSetPlayers([...players, player], rules)
        onSearchClear();
        logger.info(`Successfully added new player to roster for ${tournament.location.diamond}, ${tournament.location.city}`);
    }

    const onCompleteRoster = () => {
        logger.info(`Now submitting a new roster for ${tournament.location.diamond}, ${tournament.location.city}`);
        const [isValid] = isValidRoster(players, rules);
        if (!isValid) return alert("This roster does not meet division requirements");
        const updatedDraft = removeKeyFromObjectCopy(user.playerStats.draftRosters || {}, `${tournament._id}`);
        Promise.all([
            axios({
                method: roster ? 'put' : 'post',
                url: '/api/tournament/register/roaster',
                headers: {
                    id: roster ? roster._id : '',
                    Authorization: `Bearer ${token}`
                },
                data: {
                    division: registration.team.division,
                    userId: user._id,
                    tournamentId: tournament._id,
                    registrationId: registration._id,
                    players: players
                }
            }),
            axios({
                url: '/api/user',
                method: 'put',
                headers: { 'Authorization': `Bearer ${token}` },
                data: {
                    user: {
                        ...user,
                        playerStats: {
                            ...user.playerStats,
                            pastRosters: {
                                ...(user.playerStats.pastRosters || {}),
                                [registration.team.division]: {
                                    division: registration.team.division,
                                    userId: user._id,
                                    tournamentId: tournament._id,
                                    registrationId: registration._id,
                                    players: players
                                }
                            },
                            draftRosters: updatedDraft
                        }
                    }
                }
            })
        ]).then(() => {
            logger.info(`Successfully submitted roster for ${tournament.location.diamond}, ${tournament.location.city}`);
            setSuccess({
                show: true,
                title: `Successfully ${roster ? "updated" : "created"} your roster`
            });
        }).catch(([tourny_err]) => {
            if (tourny_err) alert(tourny_err.response.data);
            logger.error(`Failed to submit roster for ${tournament.location.diamond}, ${tournament.location.city} with error ${tourny_err.message}`);
        });
    }

    const useLastRoster = () => {
        logger.info(`Now setting last used roster`);
        setUsedLastRoster(true);
        onSetPlayers(lastRosterForDivision.players, rules);
        logger.info(`Successfully set last used roster`);
    }

    const onSaveDraft = () => {
        try {
            logger.info(`Atempting to save draft roster for ${tournament.location.diamond}, ${tournament.location.city}`);
            axios({
                url: '/api/user',
                method: 'put',
                headers: { 'Authorization': `Bearer ${token}` },
                data: {
                    user: {
                        ...user,
                        playerStats: {
                            ...user.playerStats,
                            draftRosters: {
                                ...(user.playerStats.draftRosters || {}),
                                [`${tournament._id}`]: {
                                    division: registration.team.division,
                                    userId: user._id,
                                    tournamentId: tournament._id,
                                    registrationId: registration._id,
                                    players: players
                                }
                            }
                        }
                    }
                }
            }).then(() => {
                logger.info(`Successfully saved draft roster for ${tournament.location.diamond}, ${tournament.location.city}`);
                setSuccess({
                    show: true,
                    title: `Successfully saved a draft of your roster`
                });
            }).catch(err => {
                logger.error(`Failed to save draft roster for ${tournament.location.diamond}, ${tournament.location.city} with error ${err.message}`)
                alert(`Failed to save your draft of this roster with error:`, err.response.data);
            });
        } catch (err) {
            logger.error(`Failed to save draft roster with error ${err.message}`)
        }
    }

    return <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={true}
    >
        {success.show ?
            <Success title={success.title} /> :
            <Paper sx={{ position: 'absolute', inset: 'auto 2% auto 2%', p: '1rem', maxWidth: { xs: "100%", sm: "95%", md: "85%", lg: "75%", xl: "55%" }, margin: 'auto' }}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Typography variant="p">{`${tournament.location.diamond}, ${tournament.location.city}`}</Typography>
                    </Grid>
                    <Grid item xs={6} lg={5} sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                        <Typography variant="p">Division: <b>{registration.team.division}</b></Typography>
                    </Grid>
                    <Grid item xs={6} lg={2} sx={{ display: 'flex', justifyContent: { xs: "end", md: 'center' }, alignItems: 'center' }}>
                        <Typography variant="p" color={players.length < 10 ? 'error' : 'success'}>Players: {players.length}/25</Typography>
                    </Grid>
                    {!viewing && <Grid item xs={12} lg={5} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'end' }}>
                        {players.length >= 10 && validRoster && <Button onClick={() => setRosterVerified(prevs => ({ ...prevs, show: true }))} sx={{ borderRadius: 50, width: "100%", margin: '10px 0' }} variant="outlined" startIcon={<CheckCircleOutline />}>{roster ? "Update" : "Submit"} Roster</Button>}
                    </Grid>}
                </Grid>
                {!validRoster && (
                    <Stack>
                        <Typography color="error" variant="h6">Invalid Roster</Typography>
                        <Typography color="error" variant="p" sx={{ fontSize: "12px" }}>Your roster is not valid for the {registration.team.division} division.</Typography>
                        <Typography color="error" variant="p" sx={{ fontSize: "12px" }}>{rules.message}</Typography>
                    </Stack>
                )}
                <br />
                {rosterVerified.show && <Dialog open={rosterVerified.show} maxWidth="sm" fullWidth scroll="paper">
                    <DialogTitle>Verify Roster</DialogTitle>
                    <DialogContent dividers={true}>
                        {rosterVerificationText['en']}
                    </DialogContent>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', margin: '20px' }}>
                        <Button color='error' onClick={() => setRosterVerified(prevs => ({ ...prevs, show: false }))}>Cancel</Button>
                        <Button onClick={onCompleteRoster} color="primary">Verify</Button>
                    </div>
                </Dialog>}

                {!viewing && <SearchBar title={"for email, name, or phone #"} searchRoute={`/api/tournament/register/roaster`} onComplete={onSearchComplete} onClear={onSearchClear} setIsSearching={setIsSearching} />}
                {isSearching && <Typography variant="p" align="left">Searching for players...</Typography>}
                {searched.results.length ? <hr /> : <br />}
                <Box sx={{ maxHeight: '30vh', overflowY: "scroll" }}>
                    {!searched.results.length && searched.wasSearched ?
                        <Typography variant="p">No eligible players found</Typography>
                        : searched.results.map((player) => {
                            return <PlayerItem player={player}>
                                {count + rules[player.rank] < rules.max ?
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        startIcon={<Add />}
                                        sx={{ borderRadius: '50rem' }}
                                        onClick={() => onAddPlayer(player)}
                                    >
                                        Add
                                    </Button> :
                                    <Button
                                        variant="outlined"
                                        color="error"
                                        startIcon={<Add />}
                                        sx={{ borderRadius: '50rem' }}
                                    >
                                        N / E
                                    </Button>
                                }

                            </PlayerItem>
                        })}
                </Box>
                <hr />
                <Box sx={{ maxHeight: '35vh', overflowY: "scroll" }}>
                    {players.map((player, index) => {
                        return <PlayerItem player={player}>
                            {!viewing && index !== 0 && <Button
                                variant="outlined"
                                color="error"
                                startIcon={<Remove />}
                                sx={{ borderRadius: '50rem' }}
                                onClick={() => onRemovePlayer(player, index)}
                            >
                                Remove
                            </Button>}
                        </PlayerItem>
                    })}
                </Box>
                <Grid container spacing={2}>
                    {!viewing && lastRosterForDivision && !usedLastRoster && <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Button onClick={useLastRoster} sx={{ borderRadius: 50, width: "100%", marginTop: '5px' }} variant="contained">Autofill last {registration.team.division} roster?</Button>
                    </Grid>}
                    {!viewing && <Grid item xs={12} lg={6}>
                        <Button onClick={onSaveDraft} sx={{ borderRadius: 50, width: "100%" }} variant="outlined" color="primary">Save Draft</Button>
                    </Grid>}
                    <Grid item xs={12} lg={6}>
                        <Button onClick={onClose} sx={{ borderRadius: 50, width: "100%" }} variant="outlined" color="error">Cancel</Button>
                    </Grid>
                </Grid>
            </Paper>
        }
    </Backdrop>
}
export default RosterArea;