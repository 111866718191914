import { useEffect, useState } from "react";
import { Backdrop, Box, Button, Checkbox, FormControl, Grid, InputLabel, ListItemText, MenuItem, OutlinedInput, Paper, Select, TextField, Typography } from "@mui/material";
import Success from "../../../../shared/components/Success";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { registrationActions } from "../../../../Store/Slices/Registrations/registrationSlice";
import { getDivisions } from "../../../../shared/Api/getDivisions";
import { tournamentActions } from "../../../../Store/Slices/Tournaments/tournamentSlice";

const RosterMap = {
    "int": {
        max: 5,
        E: 0,
        D: 0,
        C: 1,
        B: 2,
        A: 3
    },
    "rec": {
        max: 3,
        E: 0,
        D: 1,
        C: 2,
        B: 3,
        A: 4
    },
    "open": {
        max: 1,
        E: 0,
        D: 0,
        C: 0,
        B: 0,
        A: 0
    }
}

export const EditRegistrations = () => {
    const { show, registration } = useSelector(state => state.registrations.editRegistration);
    const regState = useSelector(state => state.registrations);
    const availableDivisions = useSelector(state => state.tournaments.divisions);
    useEffect(() => {
        if(availableDivisions.length) return;
        getDivisions().then((divisions) => {
            dispatch(tournamentActions.addDivisions(divisions[0].divisions))
        })
    }, [])
    const roster = useSelector(state => state.rosters.rosters).find(roster => roster.registrationId === registration._id);
    const dispatch = useDispatch();
    const [isEditingDivision, setIsEditingDivision] = useState(false);
    const [success, setSuccess] = useState(false)
    const [registrations, setRegistration] = useState({
        team: {
            name: '',
            captain: {
                email: '',
                firstName: '',
                lastName: '',
                phone: ''
            },
            message: ''
        }
    });
    const token = useSelector(state => state.user.token);
    const close = () => dispatch(registrationActions.stopEditingRegistration());

    const handleRegistrationChange = (event) => {
        if (!event.isTrusted) return;
        const { id, value } = event.target;
        setRegistration(prevs => ({
            ...prevs,
            team: {
                ...prevs.team,
                name: id === 'name' ? value : prevs.team.name,
                captain: {
                    ...prevs.team.captain,
                    email: id === 'email' ? value : prevs.team.captain.email,
                    firstName: id === 'firstName' ? value : prevs.team.captain.firstName,
                    lastName: id === 'lastName' ? value : prevs.team.captain.lastName,
                    phone: id === 'phone' ? value : prevs.team.captain.phone
                },
                message: id === 'comments' ? value : prevs.team.message
            }
        }));
    }
    const handleDivisionChange = (event) => {
        //check the value
        const { value } = event.target;
        const getDivision = () => {
            if (value.toLowerCase().includes('int')) {
                return 'int'
            } else if (value.toLowerCase().includes('rec')) {
                return 'rec'
            } else if (value.toLowerCase().includes('open')) {
                return 'open'
            }
        }
        const div = getDivision();
        const canChange = (roster ? roster.players.reduce((acc, player) => (acc + RosterMap[div][player.rank]), 0) : 0) < RosterMap[div].max;
        if (!canChange) return alert(`This roster is not eligible for an ${div} division. Please have the captain update the roster first`);
       
        setRegistration(prevs => ({
            ...prevs,
            team: {
                ...prevs.team,
                division: value,
            }
        }));
    }

    const updateRegistration = () => {
        var confirmed = window.confirm(`You are about to update a registration. This will NOT update the team or the user's information. Please ensure you have everything correct. Incorrect entry of data could effect the clients ability to see this registration in their "My Events". Proceed with caution`);
        if (!confirmed) return;
        Promise.all([
            roster ? axios({
                method: 'put',
                url: '/api/tournament/register/roaster',
                headers: {
                    id: roster?._id,
                    Authorization: `Bearer ${token}`
                },
                data: {
                    ...roster,
                    division: registrations.team.division
                }
            }) : (async () => {})(),
            axios({
                method: "put",
                url: "/api/tournament/register",
                headers: {
                    id: registration._id,
                    Authorization: `Bearer ${token}`
                },
                data: registrations
            })
        ]).then(() => {
            setSuccess(true);
        }).catch((err) => {
            alert(`Failed to update this registration. Error: ${err.response.data}`);
        });

    }

    useEffect(() => {
        if (show) setRegistration(registration);
    }, [registration, show])

    if (!show) return <></>

    return <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={show}>
        {success ?
            <Success title={"Successfully updated team information for this registration"} /> :
            <Paper sx={{ m: { xs: '1%', md: '10%' }, width: { xs: '98%', md: '80%' }, p: '1rem', overflowY: 'scroll', maxHeight: '95%' }}>
                <Box sx={{ display: 'flex' }}>
                    <Typography variant="h4" component="h4" gutterBottom>Update Registration</Typography>
                    <Typography variant="h4" component="p" gutterBottom>Team info only</Typography>
                </Box>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={4}>
                        <TextField sx={{ width: '100%' }} required id="name" label="Team Name" variant="standard" onChange={handleRegistrationChange} value={registrations.team.name} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={8}>
                        <TextField sx={{ width: '100%' }} required id="email" label="Captains Email" variant="standard" onChange={handleRegistrationChange} value={registrations.team.captain.email} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <TextField sx={{ width: '100%' }} required id="firstName" label="Captain's First" variant="standard" onChange={handleRegistrationChange} value={registrations.team.captain.firstName} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <TextField sx={{ width: '100%' }} required id="lastName" label="Captain's Last" variant="standard" onChange={handleRegistrationChange} value={registrations.team.captain.lastName} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <TextField sx={{ width: '100%' }} required id="phone" label="Captain's Phone" variant="standard" onChange={handleRegistrationChange} value={registrations.team.captain.phone} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <FormControl sx={{ width: '100%' }}>
                            {!isEditingDivision ? (
                                <Box display="flex" alignItems="center" justifyContent="space-between">
                                    <Typography variant="subtitle1">
                                        {registrations.team.division || "No division selected"}
                                    </Typography>
                                    <Button variant="outlined" onClick={() => setIsEditingDivision(true)}>
                                        Edit
                                    </Button>
                                </Box>
                            ) : (<>
                                <InputLabel id="division-label">Division</InputLabel>
                                <Select
                                    labelId="division-label"
                                    id="division"
                                    value={registrations.team.division}
                                    onChange={(event) => {
                                        handleDivisionChange(event);
                                    }}
                                    label="Division"
                                    input={<OutlinedInput label="Division" />}
                                >
                                    {availableDivisions.map((division) => (
                                        <MenuItem key={division} value={division}>
                                            <ListItemText primary={division} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </>
                            )}
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={8}>
                        <TextField fullWidth onChange={handleRegistrationChange} value={registrations.team.message} id='comments' label="Notes and comments" />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Button onClick={() => updateRegistration()} sx={{ borderRadius: '50rem', width: "98%", margin: "1%" }} color='secondary' variant='contained'>Update</Button>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Button onClick={close} sx={{ borderRadius: '50rem', width: "98%", margin: "1%" }} color='error' variant='outlined'>Cancel</Button>
                    </Grid>
                </Grid>
            </Paper>}
    </Backdrop>
}