// const axios = require('axios').default;

export const paymentReducers = {
    populateArray(state, { payload }) {
        let payments = payload || [];
        if (!Array.isArray(payments)) return;
        payments = payments.filter(payment => !(state.payments.some(cachedPayment => payment._id === cachedPayment._id)))
        state.payments = [
            ...state.payments,
            ...payments
        ]
    },
    clearArray(state) {
        state.payments = [];
    },
    startPayment(state, { payload }) {
        const { selected, makePayment } = payload;
        state.making = { selected, makePayment, show: true };
    },
    endPayment(state) {
        state.making = {
            selected: [],
            makePayment: false,
            show: false
        }
    }
}