import { createSlice } from '@reduxjs/toolkit';
import { registrationReducers } from './registrationReducers';

const registrationInit = {
    registration: [],
    editRegistration: {
        show: false,
        registration: {}
    },
    rosterCreation: {
        tournament: null,
        registration: null,
        roster: null
    },
    registering: {
        isRegistering: false,
        tournamentId: null,
        division: "Co-ed Rec",
        isWaitlist: false,
        setIsLoading: null,
        isAdmin: false
    },
    reporting: {
        registrations: [],
        show: false,
        type: ""
    }
}

export const registrationSlice = createSlice({
    name: 'registration',
    initialState: registrationInit,
    reducers: registrationReducers
});
export const registrationActions = registrationSlice.actions;