import { createSlice } from '@reduxjs/toolkit';
import { notificationReducers } from './notificationReducers';

export const notificationsSlice = createSlice({
    name: 'notifications',
    initialState: {
        sessionExpiry: false,
        notifications: [],
        fetched: false
    },
    reducers: notificationReducers
});
export const notificationsActions = notificationsSlice.actions;