import { Avatar, Grid, Typography } from "@mui/material";

export const PlayerItem = ({ player, children }) => {
    return <Grid container spacing={2} sx={{ p: '5px', borderBottom: '1px solid gray' }}>
        <Grid item xs={2} sx={{ display: 'flex', justifyContent: "center", alignItems: 'center' }}>
            <Avatar
                src={`/api/images/200/${player.photo}`}
                alt={`${player.firstName} ${player.lastName}`}
                sx={{ width: 50, height: 50 }}
            />
        </Grid>
        <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Grid container>
                <Grid item xs={12} md={4} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Typography variant="h6">{player.firstName} {player.lastName} ({player.rank})</Typography>
                </Grid>
                <Grid item xs={12} md={4} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Typography variant="p">{player.phone}</Typography>
                </Grid>
                <Grid item xs={12} md={4} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Typography variant="p">{player.city}</Typography>
                </Grid>
            </Grid>
        </Grid>
        <Grid item xs={4} sx={{ display: 'flex', justifyContent: "center", alignItems: 'center' }}>
            {children}
        </Grid>
    </Grid>
}