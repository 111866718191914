import { Link } from "react-router-dom";
import GradiantBackground from "../styling/GradiantBackground";
import FacebookButton from "./FacebookButton";
import InstagramButton from "./InstagramButton";
import styles from "./DesktopBar.module.css";
import { Sponsers } from "../components/Sponsers";

const DesktopBar = () => {
    var year = new Date().getFullYear();
    return <GradiantBackground><div className={styles.footer}>
        <div className="col-md-4">
            <h6>Contact Us</h6>
            <div className={styles.socials}>
                <span><FacebookButton /></span>
                <span><InstagramButton /></span>
            </div>
            <p>Tournament Inquiries: <a href="mailto:info@spfacanada.ca">info@spfacanada.ca</a></p>
            <p>Product Inquiries: <a href="mailto:orders@spfacanada.ca">orders@spfacanada.ca</a></p>
        </div>
        <div className="col-md-4">
            <Sponsers />
        </div>
        <div className="col-md-4">
            <h6>Our Policies</h6>
            <p><Link to="/privacy-policies">Privacy Policy</Link></p>
            <p><Link to="/terms-conditions">Terms and Conditions</Link></p>
            <p><Link to="/refunds-returns">Refund and Return Policy</Link></p>
            <p><Link to="/cookies">Cookies Policy</Link></p>
        </div>
    </div>
        <p className={styles.trademark}>{`Copyright © ${year} Awareness Athletics Inc.`}</p>
    </GradiantBackground>
}
export default DesktopBar;