// const axios = require('axios').default;

export const notificationReducers = {
    setNotifications(state, action) {
        state.fetched = true;
        state.notifications = action.payload;
    },
    setSessionExpiry(state, action) {
        state.sessionExpiry = action.payload;
    }
}

// notifications
//   [
//     { id: 1, title: 'New Player', message: "Please confirm *Insert name here*'s rank", link: '/messages/1' },
//     { id: 2, title: 'Registration', message: "*team name* has registered for Belleville on Feb 19th", link: '/alerts/1' },
//     { id: 3, title: 'New Player', message: "Please confirm *Insert name here*'s rank", link: '/messages/1' },
//     { id: 4, title: 'Registration', message: "*team name* has registered for Belleville on Feb 19th", link: '/alerts/1' },
//     { id: 5, title: 'New Player', message: "Please confirm *Insert name here*'s rank", link: '/messages/1' },
//     { id: 6, title: 'Registration', message: "*team name* has registered for Belleville on Feb 19th", link: '/alerts/1' },
//   ]