import axios from "axios";

export const getUsers = async (token, page = 1, limit = 500) => {
    try {
        const res = await axios({
            method: "get",
            url: '/api/user/all',
            headers: {
                Authorization: `Bearer ${token}`,
                page: page,
                limit: limit
            }
        })
        return res.data;
    } catch (err) {
        console.error(`Failed to get all users`, err);
        return null;
    }
}

export const getConveners = async (token, page = 1, limit = 500) => {
    try {
        const res = await axios({
            method: "get",
            url: '/api/user/allConveners',
            headers: {
                Authorization: `Bearer ${token}`,
                page: page,
                limit: limit
            }
        })
        return res.data;
    } catch (err) {
        console.error(`Failed to get all users`, err);
        return null;
    }
}