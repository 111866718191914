import React, { useEffect, useState } from 'react';
import { IconButton, Badge, Popover, List, ListItem, ListItemText, ListItemSecondaryAction, Button } from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';
import { getUsers } from '../../Api/getUsers';
import { notificationsActions } from '../../../Store/Slices/Notifications/notificationSlice';

// Define styles
const useStyles = makeStyles((theme) => ({
    popover: {
        // Set minimum width to 50% of the viewport width for larger screens
        minWidth: '50vw',
        [theme.breakpoints.down('sm')]: {
            // Use grid layout for mobile
            display: 'grid',
            gridTemplateColumns: '1fr',
            gridGap: theme.spacing(1),
            width: '100%', // Use 100% of the width on smaller screens
            maxWidth: '100vw', // Ensure the max width is not more than 100% of the viewport width
        },
    },
    list: {
        [theme.breakpoints.down('sm')]: {
            width: '100%', // Ensure the list uses the full width on smaller screens
        },
    },
}));

const NotificationBell = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const dispatch = useDispatch();
    const token = useSelector(state => state.user.token);
    const { notifications, fetched } = useSelector(state => state.notifications);
    useEffect(() => {
        if (fetched) return
        getUsers(token).then((users) => {
            if (!users) return;
            let notifications = users.filter(user => !user.playerStats.verified && user.playerStats?.rank).map((user, i) => ({
                id: i,
                title: `Please verify ${user.name.givenName} ${user.name.familyName} for rank ${user.playerStats.rank}`,
                link: "/dashboard/users"
            }));
            dispatch(notificationsActions.setNotifications(notifications))
        });
    }, [])
    const classes = useStyles();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <div>
            <IconButton color="inherit" onClick={handleClick}>
                <Badge badgeContent={notifications.length} color="secondary">
                    <NotificationsIcon />
                </Badge>
            </IconButton>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                
                className={classes.popover}
            >
                <List className={classes.list}>
                    {notifications.map((notification) => (
                        <ListItem key={notification.id} onClick={() => window.location.href = notification.link}>
                            <ListItemText primary={notification.title} secondary={notification.message} />
                            <ListItemSecondaryAction>
                                <Button size="small" onClick={() => window.location.href = notification.link}>View</Button>
                            </ListItemSecondaryAction>
                        </ListItem>
                    ))}
                </List>
            </Popover>
        </div>
    );
};

export default NotificationBell;
