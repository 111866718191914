export const getRegistrationTotal = (allPayment, registration) => {
    const payments = allPayment.filter((payment) => payment.Invoice === registration._id);
    if (payments.length < 1) return {
        paid: 0,
        balance: registration.OrderTotal
    };

    var totalPaid = 0;
    payments.map((paid) => {
        totalPaid += paid.amount;
        return totalPaid;
    });

    return {
        paid: totalPaid,
        balance: registration.OrderTotal - totalPaid
    }
}