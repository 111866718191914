export const userReducers = {
    setToken(state, action) {
        state.token = action.payload
    },
    clearToken(state) {
        state.token = '';
    },
    setAccount(state, action) {
        state.account = action.payload;
    },
    updateImage(state, action) {
        state.account.profileImage = action.payload;
    },
    updateAddress(state, { payload }) {
        const { address } = payload;
        if (!address) return;
        state.account.address = address;
    },
    clearAccount(state) {
        state.account.startDate = '';
        state.account.username = '';
        state.account.name.givenName = '';
        state.account.name.middleName = '';
        state.account.name.familyName = '';
        state.account.phone = '';
        state.account.DateOfBirth = '';
        state.account.roles = '';
        state.account.profileImage = '';
        state.account.active = '';
        state.account.team.name = '';
        state.account.team.status = '';

        state.account.permissions = {}
        state.account.permissions.website.access = true
        state.account.permissions.admin.access = false
        state.account.permissions.convener.access = false
    },
    logout(state) {
        state.auth = false;
    },
    login(state) {
        state.auth = true;
    },
    setRegistrations(state, action) {
        state.registrations = action.payload
    },
    clearRegistrations(state) {
        state.registrations = [];
    },
    setOrders(state, action) {
        state.orders = action.payload
    },
    clearOrders(state) {
        state.orders = [];
    },
    setPayments(state, action) {
        state.payments = action.payload
    },
    clearPayments(state) {
        state.payments = [];
    },
    setRosters(state, action) {
        state.rosters = action.payload
    },
    clearRosters(state) {
        state.rosters = [];
    },
}