import * as React from 'react';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import { notificationsActions } from '../../Store/Slices/Notifications/notificationSlice';

const SessionExpiry = () => {
    const open = useSelector(state => state.notifications.sessionExpiry);
    const dispatch = useDispatch();

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') return;
        dispatch(notificationsActions.setSessionExpiry(false));
    };

    const action = (
        <React.Fragment>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );

    return <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message="Session is about to expire"
        action={action}
    />
}
export default SessionExpiry;