import React from 'react';
import { Badge, styled } from '@mui/material';

const AutismAwarenessBadge = ({ text }) => {
    // Custom styled badge with gradient background
    const GradientBadge = styled(Badge)(({ theme }) => ({
        '& .MuiBadge-badge': {
            background: 'linear-gradient(45deg, #55B3F3, #FFD700)',
            color: theme.palette.getContrastText('#0000A0'), // Ensures text color is legible against the background
            minWidth: "200px",
            padding: "15px",
            borderRadius: "25rem",
        },

    }));
    
    return <GradientBadge badgeContent={text} />
};
export default AutismAwarenessBadge;