export const maskPhoneNumber = (digits) => {
    // Convert the input to a string in case it's a number
    const digitStr = digits.toString();

    // Validate that the input is numeric
    if (!digitStr.match(/^\d+$/)) {
        return digits;
    }

    // Ensure there are at least 4 digits to work with
    if (digitStr.length < 4) {
        return digits;
    }

    // Capture the last 4 digits
    const lastFourDigits = digitStr.slice(-4);

    // Format and return the phone number
    return `xxx-xxx-${lastFourDigits}`;
}