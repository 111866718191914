import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
    Box,
    Button,
    Grid,
    TextField,
    Typography,
    Alert,
    FormControl,
    InputLabel,
    IconButton,
    Tooltip,
    MenuItem,
    Select,
    CircularProgress,
    Dialog,
    DialogTitle,
    DialogContent
} from "@mui/material";
import InfoIcon from '@mui/icons-material/Info';
import PasswordStrengthBar from 'react-password-strength-bar';
import ForgotPasswordFrom from './ForgotPasswordForm';
import { useDispatch, useSelector } from "react-redux";
import { userActions } from "../../../Store/Slices/User/userSlice";
import axios from 'axios';
import formatPhoneNumber from "../../../shared/lib/formatPhoneNumber";
import trimPhoneNumber from '../../../shared/lib/trimPhoneNumber';
import AddressForm from "./AddressForm";
import waiver from '../../../data/waiver.json';
import ranking from '../../../data/ranking.json';
import AgreementCheckbox from "./AgreementCheckbox";

const Login = () => {
    const userAuth = useSelector(state => state.user.auth);
    let navigate = useNavigate();
    useEffect(() => {
        if (!userAuth) {
            return;
        }
        navigate('/');
    }, [userAuth, navigate]);
    const dispatch = useDispatch();
    const [score, setScore] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [waiverAgreed, setwaiverAgreed] = useState(false);
    const [feedback, setFeedback] = useState([]);
    const [error, setError] = useState('');
    const [login, setLogin] = useState(true);
    const [rankingDetails, setRankingDetails] = useState({
        shown: false,
        show: false
    })
    const [forgot, setForgot] = useState(false);
    const [user, setUser] = useState({
        givenName: '',
        familyName: '',
        phone: '',
        address: {
            address1: '',
            address2: '',
            city: '',
            province: '',
            postal: '',
        },
        playerStats: {
            rank: ''
        },
        username: '',
        password: '',
        confirmation: ''
    });
    const setUserHandler = (event) => {
        const { id, value } = event.target;
        setUser((prevs) => ({
            ...prevs,
            [id]: value
        }));
    }
    const updateRank = (event) => {
        const rank = event.target.value;
        setUser((prevs) => ({
            ...prevs,
            playerStats: {
                rank
            }
        }));
    }
    const updateAddress = (address) => {
        setUser((prevs) => ({
            ...prevs,
            address: address
        }));
    }
    const sendLoginRequest = (event) => {
        if (!event.isTrusted) return;
        setIsLoading(true);
        axios.post('/api/user/login', {
            username: user.username.toLowerCase(),
            password: user.password
        }).then((res) => {
            dispatch(userActions.setToken(res.data));
            setError('');
            setIsLoading(false);
            window.location.replace('/')
        }).catch((error) => {
            setIsLoading(false);
            const response = error.response.data;
            if (response === 'Unauthorized') {
                setError('Incorrect username or password.');
                return;
            }
            if (response.includes('No account')) {
                setError(response);
            }
            setError(response);
        });
    }
    const sendRegisterRequest = (event) => {
        if (!event.isTrusted) return;
        setIsLoading(true);
        try {
            if (user.givenName.length < 2 || user.familyName.length < 2) {
                setError('Please fill in a your first and last name');
                return;
            }
            if (user.playerStats.rank.length < 1) {
                setError("Please fill in a your rank. IF you're not a play please select 'Not Sure'");
                return;
            }
            if (user.address.city.length < 4) {
                setError('Please fill in a your city');
                return;
            }
            //TODO update this for address!
            if (user.address.province.length < 2) {
                setError('Please fill in a your province');
                return;
            }
            if (user.password !== user.confirmation) {
                setError('Confirmation does not match your password');
                return;
            }
            if (score < 3) {
                setError('Password is too week. Please pick a stronger password');
                return;
            }
            const numberTrim = trimPhoneNumber(user.phone);
            if (numberTrim.length !== 10) {
                setError('Please fill in a valid 10 digit phone number');
                return;
            }
            if (!user.username.includes('@') || !user.username.includes('.')) {
                setError('Please fill in a valid email address');
                return;
            }
        } finally {
            setIsLoading(false);
        }

        axios({
            method: 'post',
            url: '/api/user/register',
            data: user
        }).then((res) => {
            dispatch(userActions.setToken(res.data.token));
            setError('');
            setIsLoading(false);
            window.location.replace('/')
        }).catch((error) => {
            const response = error.response.data;
            setIsLoading(false);
            if (response === 'Unauthorized') {
                setError('Incorrect username or password.');
                return;
            }
            if (response.includes('No account')) {
                setError(response);
            }
            setError(response);
        });
    }
    const scoreChecker = (score, feedback) => {
        setScore(score);
        setFeedback(feedback.suggestions || []);
    }

    const handleFirstRankClick = () => {
        if (rankingDetails.shown) return;
        setRankingDetails(prevs => ({
            ...prevs,
            show: true
        }));
    }

    const handleRankingViewClose = () => {
        setRankingDetails({
            shown: true,
            show: false
        })
    }

    return <Box sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        m: '1%',
        width: '98%'
    }}>
        <Grid container spacing={2}>
            {!login && <Grid item xs={12}>
                <Typography sx={{ width: '100%', margin: "2% 0" }} align="start" variant="h5">
                    Personal Information
                </Typography>
            </Grid>}
            {!login && <Grid item xs={12} md={6}>
                <TextField fullWidth id="givenName" label="First Name" value={user.givenName} onChange={setUserHandler} />
            </Grid>}
            {!login && <Grid item xs={12} md={6}>
                <TextField fullWidth id="familyName" label="Last Name" value={user.familyName} onChange={setUserHandler} />
            </Grid>}
            <Grid item xs={12}>
                <TextField fullWidth id="username" label="Email Address" value={user.username} onChange={setUserHandler} />
            </Grid>
            {!login && <Grid item xs={12} sm={6} md={4}>
                <TextField fullWidth id="phone" label="Cell Phone" value={formatPhoneNumber(user.phone)} onChange={setUserHandler} />
            </Grid>}
            {!login && <Grid item xs={12} sm={12} md={4}>
                <Box display="flex" alignItems="center">
                    <FormControl fullWidth variant="outlined">
                        <InputLabel id="demo-simple-select-filled-label">Player Rank</InputLabel>
                        <Select
                            onOpen={handleFirstRankClick}
                            labelId="demo-simple-select-filled-label"
                            id="demo-simple-select-filled"
                            value={user.playerStats.rank}
                            label="Player Rank"
                            onChange={updateRank}
                        >
                            <MenuItem value={"E"}>E</MenuItem>
                            <MenuItem value={"D"}>D</MenuItem>
                            <MenuItem value={"C"}>C</MenuItem>
                            <MenuItem value={"B"}>B</MenuItem>
                            <MenuItem value={"A"}>A</MenuItem>
                        </Select>
                    </FormControl>
                    <Tooltip title={ranking['en']}>
                        <IconButton>
                            <InfoIcon color={"primary"} />
                        </IconButton>
                    </Tooltip>
                </Box>
            </Grid>}

            {!login && <Grid item xs={12} sx={{ padding: '2% 0' }}>
                <AddressForm updateAddress={updateAddress} />
            </Grid>}

            {!login && <Grid item xs={12}>
                <Typography sx={{ width: '100%', margin: "2% 0" }} align="start" variant="h5">
                    Account Password
                </Typography>
            </Grid>}

            <Grid item xs={12} sm={!login ? 6 : 12}>
                <TextField fullWidth type="password" id="password" label="Password" value={user.password} onChange={setUserHandler} />
                {!login && <PasswordStrengthBar password={user.password} minLength={8} onChangeScore={scoreChecker} />}
                {feedback.map((message) => {
                    return <Typography key={message} sx={{ display: 'block' }} variant='p'><InfoIcon color='info' />{message}</Typography>
                })}
            </Grid>
            {!login && <Grid item xs={12} sm={6}>
                <TextField fullWidth type="password" id="confirmation" label="Confirm Password" value={user.confirmation} onChange={setUserHandler} />
            </Grid>}
            {!login && <Grid item xs={12} sm={6}>
                <AgreementCheckbox title={"Player waiver"} open={true} onSuccess={() => setwaiverAgreed(true)}>
                    {waiver["en"]}
                </AgreementCheckbox>
            </Grid>}
            {!login && <Dialog open={rankingDetails.show} onClose={handleRankingViewClose} maxWidth="sm" fullWidth scroll="paper">
                <DialogTitle>Ranking Details</DialogTitle>
                <DialogContent dividers={true}>
                    {ranking['en']}
                </DialogContent>
            </Dialog>}
           
        </Grid>

        {error && <Alert sx={{ width: '100%', mt: '1rem' }} severity="error">{error}</Alert>}
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant='p'>{login ? `Don't have an account?` : `Already have an account?`}</Typography>
            <Button sx={{ fontSize: 'inherit', textTransform: 'inherit' }} onClick={() => { setLogin(!login); setError(''); setwaiverAgreed(false); }}>{login ? `Create One` : `Login Here`}</Button>
        </Box>
        {
            !login ?
                waiverAgreed ?
                    <Button disabled={isLoading} onClick={sendRegisterRequest} color={'success'} fullWidth sx={{ borderRadius: '50rem' }} variant='contained'>
                        {isLoading ? <CircularProgress size={24} /> : `Create Account`}
                    </Button> :
                    <Button disabled onClick={sendRegisterRequest} color={'success'} fullWidth sx={{ borderRadius: '50rem' }} variant='contained'>{`Create Account`}</Button>
                : <Button disabled={isLoading} onClick={sendLoginRequest} color={'primary'} fullWidth sx={{ borderRadius: '50rem' }} variant='contained'>
                    {isLoading ? <CircularProgress size={24} /> : "Login"}
                </Button>
        }

        <Button onClick={() => { setForgot(!forgot) }} variant='text' color='action'>Forgot Password</Button>
        {forgot && <ForgotPasswordFrom close={() => { setForgot(!forgot) }} />}
    </Box>
}
export default Login;

